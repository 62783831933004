/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-07-11 15:53:56
 */
import { request } from "@/request/request";

export default {
  // 上传图片
  uploadImage(data) {
    return request({
      url: "/file/uploadImage",
      method: "post",
      data,
    });
  },
  // 获取七牛云token
  getToken(data) {
    return request({
      url: "/file/getToken",
      method: "get",
      data,
    });
  },
  // 提交结构搭建
  submitAnswer(data) {
    return request({
      url: "/tbl-structure-answer/submitAnswer",
      method: "post",
      data,
    });
  },
  // 获取结构搭建
  getAnswer(data) {
    return request({
      url: "/tbl-structure-answer/getAnswer",
      method: "get",
      data,
    });
  },
};
