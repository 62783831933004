/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2024-05-29 18:11:33
 */
import { defineStore } from 'pinia';

const useCommonStore = defineStore("common", {
  state: () => ({
    // 比赛信息
    comMess: {},
    // 摄像头定时器
    cameraInterval: "",
    // 是否展示联系模块
    isShowContact: false,
    // 是否加载中
    isLoading: false,
    // 日志id
    logId: null,
    // 是否展示媒体
    isShowMedia: false,
    // 媒体引用
    mediaRef: "",
    // 拍摄定时器
    shotInterval: "",
    // 是否展示上级比赛成绩弹窗
    isShowScoreModal: false,
    // 是否开启摄像头
    isCameraOn: false,
    // 赛期map
    periodMap: { 1: "地区赛", 2: "市赛", 3: "省赛" },
    // 是否直接指定是某个赛事级别： 0: 原逻辑 1：预选赛 2：市赛 3:省赛
    RaceType: 0,
    // 后端传入是否开启摄像头：0：关闭，1：开启
    cameraState: 0,
  }),

  getters: {},
  actions: {
    SET_COMMESS(comMess) {
      this.comMess = comMess;
    },
    SET_CAMERA_INTERVAL(cameraInterval) {
      this.cameraInterval = cameraInterval;
    },
    CLEAR_CAMERA_INTERVAL() {
      clearInterval(this.cameraInterval);
      this.cameraInterval = "";
    },
    SET_IS_SHOW_CONTACT(isShowContact) {
      this.isShowContact = isShowContact;
    },
    SET_IS_LOADING(isLoading) {
      this.isLoading = isLoading;
    },
    SET_LOGID(logId) {
      this.logId = logId;
    },
    SET_IS_SHOW_MEDIA(isShowMedia) {
      this.isShowMedia = isShowMedia;
    },
    SET_MEDIAREF(mediaRef) {
      this.mediaRef = mediaRef;
    },
    SET_SHOT_INTERVAL(shotInterval) {
      this.shotInterval = shotInterval;
    },
    CLEAR_SHOT_INTERVAL() {
      clearInterval(this.shotInterval);
      this.shotInterval = "";
    },
    SET_IS_SHOW_SCORE_MODAL(isShowScoreModal) {
      this.isShowScoreModal = isShowScoreModal;
    },
    SET_IS_CAMERA_ON(isCameraOn) {
      this.isCameraOn = isCameraOn;
    },
    SET_RACE_TYPE(RaceType) {
      this.RaceType = RaceType;
    },
    SET_CAMERA_STATE(cameraState) {
      this.cameraState = cameraState;
    },
  },
});

export default useCommonStore;
