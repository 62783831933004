/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2023-07-02 13:56:10
 */
/*
 * @Date: 2022-08-16 09:31:08
 * @LastEditTime: 2023-07-02 13:44:41
 */

import App from './App.vue';
import router from './router';
import api from '@/api';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { Initializer, rewirteLog } from '@/core/bootstrap';
import { lazyUse } from '@/core/use';
import 'amfe-flexible'; // rem转化的工具
import { Button, Tooltip } from "ant-design-vue";

import './global.css';
import ls from '@/utils/storageUtil';

const pinia = createPinia();
const app = createApp(App);

// 使用 ant-design-vue 组件
app.use(Button);
app.use(Tooltip);
lazyUse(app);
app.use(pinia);
app.use(Initializer);
app.use(rewirteLog);
// 注册全局api
app.config.globalProperties.$api = api;
app.config.globalProperties.$router = router;
app.config.globalProperties.$$ls = ls;

app.use(router).mount('#app');