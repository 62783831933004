/*
 * @Author: gyh
 * @Desc：静态文本配置项
 * @Date: 2024-04-19 15:33:54
 * @LastEditTime: 2024-06-07 14:27:21
 */
const config = {
  login: {
    bg: {
      qck: require('@/assets/login/bg__qck.png'),
      hdycp: require('@/assets/login/bg__hdycp.png'),
      sdszsy: require('@/assets/login/bg__sdszsy.png'),
    },
    logo: {
      qck: require('@/assets/login/logo__qck.png'),
      hdycp: require('@/assets/login/logo__hdycp.png'),
      sdszsy: require('@/assets/login/logo__sdszsy.png'),
    },
    competition: {
      qck: '',
      hdycp: '第二届花都区青少年编程科技竞赛',
      sdszsy: '山东省青少年数字素养提升技能大赛',
    },
    title: {
      qck: '华南理工大学软件编程挑战赛',
      hdycp: '第二届花都区青少年编程科技竞赛',
      sdszsy: 'NeuroMaster 脑科学人工智能挑战赛',
    },
    subTitle: {
      qck: '软件编程赛道',
      hdycp: '编程赛道',
      sdszsy: '编程思维项目',
    },
  },
  competition: {
    qck: '2023-2024年第二届 广东省青少年科技创客大赛',
    hdycp: '第二届花都区青少年编程科技竞赛',
    sdszsy: '山东省青少年数字素养提升技能大赛',
  },
  basicLayout: {
    title: {
      qck: '广东省青少年科技创客大赛',
      hdycp: '第二届花都区青少年编程科技竞赛',
      sdszsy: '山东省青少年数字素养提升技能大赛',
    },
  },
  enrollForm: {
    title: {
      qck: '2023-2024年第二届 广东省青少年科技创客大赛',
      hdycp: '第二届花都区青少年编程科技竞赛',
      sdszsy: '',
    },
  },
};

export { config };
