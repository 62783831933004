<!--
 * @Date: 2023-05-18 17:31:12
 * @LastEditTime: 2024-06-17 10:22:25
-->
<template>
  <div class="contact_box">
    <template v-for="contact in contacts" :key="contact.id">
      <a-tooltip placement="left" v-if="contact.phone">
        <template #title>
          <span>{{ contact.phone }}</span>
        </template>
        <div
          class="contact"
          @click="go2page(contact)"
          @mouseenter="contact.isActive = true"
          @mouseleave="mouseleave"
        >
          <img :src="contact.icon" class="icon" />
          <p class="name">{{ contact.name }}</p>
          <template v-if="VUE_APP_GAME != 'sdszsy' && contact.code">
            <div class="show_box" v-show="contact.isActive">
              <img class="code" :src="contact.code" v-if="contact.code" />
              <p
                :class="['text', { special_text: contact.isSpecial }]"
                v-html="contact.text"
              ></p>
            </div>
          </template>
        </div>
      </a-tooltip>
      <div
        class="contact"
        @click="go2page(contact)"
        @mouseenter="contact.isActive = true"
        @mouseleave="mouseleave"
        v-else
      >
        <img :src="contact.icon" class="icon" />
        <p class="name">{{ contact.name }}</p>
        <template v-if="VUE_APP_GAME != 'sdszsy' && contact.code">
          <div class="show_box" v-show="contact.isActive">
            <img class="code" :src="contact.code" v-if="contact.code" />
            <p
              :class="['text', { special_text: contact.isSpecial }]"
              v-html="contact.text"
            ></p>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from "vue";

let { VUE_APP_GAME } = process.env;

const contacts = reactive([]),
  mouseleave = () => {
    const cache = [...contacts];
    contacts.length = 0;
    cache.forEach((contact) => {
      contact.isActive = false;
      contacts.push(contact);
    });
  },
  go2page = (e) => {
    // 有链接就跳转，没链接不处理
    if (e.link) {
      const link = document.createElement("a");
      link.href = e.link;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    }
  };

watchEffect(() => {
  let arr = [];
  if (VUE_APP_GAME == "sdszsy")
    arr = [
      {
        id: 0,
        name: "平台使用说明",
        text: "平台使用说明",
        link: "https://docs.qq.com/doc/p/8302c6e42ad46f362412d41e0e8597532b7006b6",
        icon: require("@/assets/contact/eventRules.png"),
        // code: require('@/assets/contact/instructions.png'),
        isActive: false,
      },
    ];
  else if (VUE_APP_GAME == "hdycp")
    arr = [
      // {
      //   id: 0,
      //   name: "平台使用说明",
      //   text: "平台使用说明",
      //   icon: require("@/assets/contact/eventRules.png"),
      //   link: "https://docs.qq.com/doc/p/8302c6e42ad46f362412d41e0e8597532b7006b6",
      //   isActive: false,
      // },
      // {
      //   id: 1,
      //   name: "赛事规则",
      //   text: "赛事规则",
      //   icon: require("@/assets/contact/eventRules.png"),
      //   // code: require("@/assets/contact/qck/rule.png"),
      //   link: "https://cdn.codedancing.cn/%E9%99%84%E4%BB%B61%EF%BC%9A%E9%9D%92%E5%B0%91%E5%B9%B4%E7%AE%97%E6%B3%95%E7%BC%96%E7%A8%8B%E8%AE%BE%E8%AE%A1%E6%AF%94%E8%B5%9B%EF%BC%88%E5%88%9D%E8%B5%9B%EF%BC%89%E7%A7%A9%E5%BA%8F%E5%86%8C.pdf",
      //   isActive: false,
      // },
    ];
  else
    arr = [
      {
        id: 0,
        name: "平台使用说明",
        text: "平台使用说明",
        icon: require("@/assets/contact/eventRules.png"),
        link: "https://docs.qq.com/doc/p/8302c6e42ad46f362412d41e0e8597532b7006b6",
        // code: require('@/assets/contact/instructions.png'),
        isActive: false,
      },
      {
        id: 1,
        name: "赛事规则",
        text: "赛事规则",
        icon: require("@/assets/contact/eventRules.png"),
        // code: require("@/assets/contact/qck/rule.png"),
        link: "https://docs.qq.com/doc/p/47f2dfba1e67d160cebab33c490a9929f97d94d9",
        isActive: false,
      },
      {
        id: 2,
        name: "赛事咨询",
        text: "赛事咨询",
        icon: require("@/assets/contact/consultation.png"),
        // code: require('@/assets/contact/qck/consulting.png'),
        phone: 18588008028,
        isActive: false,
      },
      {
        id: 3,
        name: "各赛区时间表",
        text: "各赛区时间表",
        icon: require("@/assets/contact/eventRules.png"),
        link: "https://docs.qq.com/doc/p/b6e091e8c1e2457fe4a874d47ff8cb187b9a6837",
        // code: require('@/assets/contact/instructions.png'),
        isActive: false,
      },
      // {
      //   id: 3,
      //   name: '赛事通知群 1',
      //   text: '华工软件编程挑战赛通知群1<br/>QQ群号: 463737040',
      //   icon: require('@/assets/contact/notificationGroup.png'),
      //   code: require('@/assets/contact/qck/1.png'),
      //   isActive: false,
      //   isSpecial: true,
      // },
      // {
      //   id: 4,
      //   name: '赛事通知群 2',
      //   text: '华工软件编程挑战赛通知群2<br/>QQ群号: 615294745',
      //   icon: require('@/assets/contact/notificationGroup.png'),
      //   code: require('@/assets/contact/qck/2.png'),
      //   isActive: false,
      //   isSpecial: true,
      // },
      // {
      //   id: 5,
      //   name: '赛事通知群 3',
      //   text: '华工软件编程挑战赛通知群3<br/>QQ群号: 592900132',
      //   icon: require('@/assets/contact/notificationGroup.png'),
      //   code: require('@/assets/contact/qck/3.png'),
      //   isActive: false,
      //   isSpecial: true,
      // },
      // {
      //   id: 6,
      //   name: '技术咨询',
      //   text: `
      //   <ul style="list-style-type: none">
      //     <li style="line-height: 0.125rem;">技术咨询QQ：529750028</li>
      //   </ul>`,
      //   // <li style="line-height: 0.125rem;">技术咨询电话：18666717012</li>
      //   // <li style="line-height: 0.125rem;">技术咨询电话：13535088091</li>
      //   icon: require('@/assets/contact/consultation.png'),
      //   isActive: false,
      //   isSpecial: true,
      // },
    ];

  arr.forEach((item) => {
    contacts.push(item);
  });
});
</script>

<style lang="less" scoped>
.contact_box {
  height: fit-content;
  padding: 16px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  // bottom: 392px;
  right: 0;

  .contact {
    min-width: 164px;
    height: 40px;
    background: #0052d9;
    border-radius: 4px;
    padding: 0 16px;
    // padding-left: 16px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:first-of-type) {
      margin-top: 8px;
    }

    .icon {
      width: 28px;
      height: 28px;
    }

    .name {
      height: 28px;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 28px;
      margin-left: 8px;
    }

    .show_box {
      width: fit-content;
      min-width: 248px;
      height: fit-content;
      padding: 8px 4px 10px 4px;
      box-sizing: border-box;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 0;
      left: -256px;

      .code {
        width: 240px;
        height: 240px;
      }

      .text {
        text-align: center;
        margin-top: 8px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.9);
        line-height: 24px;

        &.special_text {
          margin-top: 2px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.9);
          line-height: 20px;
        }
      }
    }
  }
}
</style>
