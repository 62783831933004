/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2023-03-15 17:22:27
 */
import PageLayout from "@/layout/PageLayout";

export default [
  {
    path: "/",
    redirect: "/user",
  },
  {
    path: "/user",
    name: "user",
    component: PageLayout,
    redirect: "/user/login",
    children: [
      {
        path: "login",
        name: "login",
        component: () => import("@/views/user/login"),
      },
    ],
  },
];
