<!--
 * @Date: 2023-02-14 09:56:13
 * @LastEditTime: 2024-05-30 17:40:57
-->
<template>
  <a-config-provider :locale="locale">
    <!-- 正常显示模块 -->
    <template v-if="isShowView">
      <router-view />
    </template>
    <!-- 错误提示模块 -->
    <errorTips v-else />
    <!-- 右侧联系模块 -->
    <ContactBox v-if="isShowContact" />
    <!-- 摄像头模块 -->
    <div class="getMediaError" v-if="isShowMedia && !mediaOn"></div>
    <MediaDevices
      ref="mediaRef"
      v-if="isShowMedia"
      @cameraChange="(e) => onChangeCamera(e)"
    />
  </a-config-provider>
</template>

<script setup>
import { getCurrentInstance, ref, computed, watch } from 'vue';
import { useCommonStore, useUserStore } from '@/store';
import { ContactBox, MediaDevices } from '@/components';
import { browserRelease } from '@/utils';

import dayjs from 'dayjs';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import 'dayjs/locale/zh-cn';
import errorTips from '@/views/exception/errorTips.vue';

dayjs.locale('zh-cn');

let { proxy } = getCurrentInstance(),
  // { VUE_APP_MEDIA } = process.env,
  locale = zhCN,
  commonStore = useCommonStore(),
  userStore = useUserStore();

const isShowView = computed(() => {
  // 系统设置只能通过pc进行网站的访问，设备版本也有一定的要求
  const { isPc, isAllowedUse, isNeededUpdate, win } = browserRelease();
  return isPc && isAllowedUse && !isNeededUpdate && !win?.isXp && !win?.isWin7;
});
// 是否展示联系模块
const isShowContact = computed(() => {
  return commonStore.isShowContact;
});
// 摄像头状态
const mediaOn = ref(false),
  // 是否展示摄像头模块
  isShowMedia = computed(() => {
    return commonStore.isShowMedia && commonStore.cameraState == 1;
    // return commonStore.isShowMedia && VUE_APP_MEDIA == 1;
  }),
  // 摄像头组件内部状态更改时修改摄像头状态
  onChangeCamera = (e) => {
    mediaOn.value = e;
    commonStore.SET_IS_CAMERA_ON(e);
  };
watch(
  () => userStore.state,
  (data) => {
    // 只有赛前测试和正式比赛下且摄像头配置为开启摄像头才调用摄像头组件内容功能
    // if ([0, 1].includes(data) &&  VUE_APP_MEDIA == 1) {
    if ([0, 1].includes(data) &&  commonStore.cameraState == 1) {
      setTimeout(() => {
        commonStore.SET_MEDIAREF(proxy.$refs.mediaRef);
        proxy.$refs.mediaRef.openCamera();
      }, 0);
    }
  },
  { immediate: true }
);
</script>

<style lang="less" scoped>
.getMediaError {
  width: 100%;
  height: calc(100% - 96px);
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 96px;
  left: 0;
}
</style>
