<!--
 * @Author: gyh
 * @Date: 2024-05-11 17:00:44
 * @LastEditTime: 2024-05-30 17:39:42
-->
<template>
  <div class="error_tips" :style="{ backgroundImage: bg }">
    <div class="box">
      <img src="@/assets/warning.png" alt="warning" class="svg" />
      <div class="tips">
        请在电脑PC端使用Chrome（谷歌浏览器）<br />
        或Edge浏览器最新版本进行访问
      </div>
    </div>
  </div>
</template>

<script setup>
import { config } from '@/config';
import { computed } from 'vue';

const { VUE_APP_GAME } = process.env,
  bg = computed(() => {
    return `url(${config.login.bg[VUE_APP_GAME]})`;
  });
</script>

<style lang="less" scoped>
.error_tips {
  width: 100vw;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20% 33px 0 35px;
  box-sizing: border-box;

  .box {
    width: fit-content;
    height: fit-content;
    background: #ffffff;
    border-radius: 20px;
    padding: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .svg {
      width: 25.6%;
      height: fit-content;
    }

    .tips {
      height: fit-content;
      font-size: 50px;
      font-weight: 600;
      color: rgba(61, 61, 61, 0.898);
      text-align: center;
      margin-top: 24px;
    }
  }
}
</style>
