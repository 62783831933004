/*
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-05-30 18:03:22
 */
import setupPermissionGuard from './permission';
import * as api from '@/api/index';
import { useUserStore, useCommonStore } from '@/store/index';
import { message, Modal } from 'ant-design-vue';
import { browserRelease } from '@/utils/browserUtils';

let timer = null;

function setupPageGuard(router) {
  router.beforeEach((to, from, next) => {
    // 非开发环境下使用http协议访问本网站会提示不安全的错误
    if (
      process.env.NODE_ENV !== 'development' &&
      window.location.protocol !== 'https:'
    ) {
      Modal.warning({
        title: '与此站点的连接不安全',
        content:
          '请勿在此网站输入任何敏感信息，否则可能会被攻击者窃取，请尝试使用https协议访问此站点。',
      });
      // const { hostname, host, port } = window.location;
      // window.location.href = "https://" + port ? host : hostname;
    }

    clearInterval(timer);

    // 隐藏右侧联系框显示
    useCommonStore().SET_IS_SHOW_CONTACT(
      !['home', 'enrollForm', 'examination'].includes(to.name) &&
        browserRelease().isPc
    );
    useCommonStore().SET_IS_SHOW_MEDIA(!['login'].includes(to.name));

    if (to.name != 'login' && sessionStorage.getItem('token')) {
      api.default
        .getStuState()
        .then((res) => {
          if (res) {
            // stuState==> 0：赛前测试，1：正式比赛；
            // raceState==> 0：未获得过考卷，没进入过答题页，1：已获得过考卷，进入过答题页
            const { stuState, raceState } = res.data,
              isExamState = [0, 1].includes(stuState);

            // 展示联系模块
            useCommonStore().SET_IS_SHOW_CONTACT(
              to.name == 'home' && isExamState
            );
            // 比赛状态下都会展示摄像头模块
            useCommonStore().SET_IS_SHOW_MEDIA(isExamState);
            // 设置当前用户状态到全局状态
            useUserStore().SET_STATE(stuState);
            // 这步的原因是避免用户从答题页点击返回上一页跳转回等待页，等待页触发逻辑判断直接跳转回答题页
            if (to.name === 'waiting') {
              if (from.name === 'examination') {
                next('/index');
                return;
              }
              // 不是从答题页返回即从首页或者报名表页跳转等待页的，如果raceState是1代表已经进入过答题页，那么直接跳转到答题页，如果是0代表没有进入过答题页，则跳转到等待页等待比赛开始
              if (raceState) next('/examination');
              else next();
              useCommonStore().SET_IS_LOADING(false);
            }
            // 等待页有时间判断，避免触发
            else if (to.name === 'examination') {
              console.log('stuState:', stuState, 'raceState:', raceState);
              // 判断语句可以优化一下
              // 学生状态是0：赛前测试或者1：正式比赛且已进入过比赛则next（raceState假设是0且stuState是1时，当用户点击进入比赛按钮时会先更改raceState为1）
              if (stuState == 0) next();
              else if (stuState == 1 && raceState) next();
              else {
                next('/index');
                message.error('非规定时间进入考试页!');
                useCommonStore().SET_IS_LOADING(false);
              }
            } else next();
          }
        })
        .catch(() => {
          useCommonStore().SET_IS_LOADING(false);
        });
    } else next();
  });
  router.afterEach((to, from) => {
    // 在首页、成绩页会轮询后端接口获取最新stuState以展示不同的内容
    if (to.name == 'home' || to.name == 'design') {
      timer = setInterval(() => {
        api.default.getStuState().then((res) => {
          const { stuState, raceState } = res.data;

          if (stuState == undefined) clearInterval(timer);
          useUserStore().SET_STATE(stuState);
        });
      }, 10000);
    } else if (to.name == 'examination') useCommonStore().SET_IS_LOADING(false);
  });
}

export default function createRouteGuard(router) {
  setupPageGuard(router);
  setupPermissionGuard(router);
}
