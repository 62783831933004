/*
 * @Date: 2023-02-24 10:07:14
 * @LastEditTime: 2024-05-07 11:12:15
 */
import { request } from '@/request/request';

export default {
  // 学生参赛信息
  getMatchInfo(data) {
    return request({
      url: '/tbl-stu-info/getMatchInfo',
      method: 'get',
    });
  },

  // 参赛前准备时间
  getPreMatchInfo(data) {
    return request({
      url: '/tbl-stu-info/getPreMatchInfo',
      method: 'get',
    });
  },

  // 获取当前学生所在状态
  getStuState(data) {
    return request({
      url: '/tbl-stu-info/getStuState',
      method: 'get',
    });
  },

  // 学生晋级报名
  signUp(data) {
    return request({
      url: "/tbl-stu-info/signUp",
      method: "post",
    });
  },

  // 获取当前时间及登录态
  getAccess(data) {
    return request({
      url: '/tbl-stu-info/getAccess',
      method: 'get',
    });
  },

  // 更新学生是否进入比赛
  updateAccess(data) {
    return request({
      url: '/tbl-stu-info/updateAccess',
      method: 'post',
      data,
    });
  },

  // 获取准考证信息
  getPdfState(data) {
    return request({
      url: '/tbl-stu-info/getPdfState',
      method: 'get',
      data,
    });
  },

  // 学生日志
  updateLog(data) {
    return request({
      url: '/tbl-stu-log/updateLog',
      method: 'post',
      data,
    });
  },
};
