<!--
 * @Author: gyh
 * @Date: 2024-05-11 17:00:44
 * @LastEditTime: 2024-05-30 17:39:40
-->
<template>
  <div class="error_tips" :style="{ backgroundImage: bg }">
    <template v-if="browserRelease().isPc">
      <!-- 公告 -->
      <Notice />
      <!-- 错误提示 -->
      <PcTips />
    </template>
    <!-- 错误提示 -->
    <MobileTips v-else />
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { browserRelease } from '@/utils/browserUtils';
import { Notice } from '@/components';
import { config } from '@/config';
import PcTips from './pcTips.vue';
import MobileTips from './mobileTips.vue';

const { VUE_APP_GAME } = process.env,
  bg = computed(() => {
    return `url(${config.login.bg[VUE_APP_GAME]})`;
  });
</script>

<style lang="less" scoped>
.error_tips {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
