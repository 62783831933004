<!--
 * @Date: 2023-02-14 09:56:14
 * @LastEditTime: 2024-05-24 12:01:34
-->
<template>
  <!-- 头部区域 -->
  <div class="header">
    <div class="left-box">
      <!-- <p class="title">{{ config.competition[VUE_APP_GAME] }}</p>
      <p class="title" v-if="VUE_APP_GAME != 'sdszsy'">
        {{ commonStore?.comMess?.eventName }}
      </p> -->
      <p class="title">惠州市信息科技创新大赛</p>
    </div>

    <div class="right-box">
      <div class="avatar-box">
        <img src="@/assets/home/avatar.png" alt="" />
      </div>

      <div class="mess-box">
        <p class="name">{{ name }}</p>
        <p class="account">{{ phone }}</p>
      </div>

      <template v-if="VUE_APP_GAME != 'qck'">
        <a-button
          class="ticket-btn"
          shape="round"
          @click="downloadTicket"
          v-if="!isHideTicketBtn"
          ghost
          >准考证下载</a-button
        >
        <a-button
          class="sign-btn"
          shape="round"
          ghost
          v-if="isShowScoreBtn"
          @click="commonStore.SET_IS_SHOW_SCORE_MODAL(true)"
          >市赛成绩</a-button
        >
        <a-button
          class="sign-btn"
          shape="round"
          @click="$router.push({ path: '/enrollForm' })"
          v-if="!isHideSignForm"
          ghost
          >参赛报名表</a-button
        >
      </template>
      <a-button
        class="logout-btn"
        shape="round"
        @click="logout"
        v-if="router.name != 'enrollForm'"
        >退出登录</a-button
      >
    </div>
  </div>

  <div class="main" id="main">
    <router-view @signAction="signAction" />
  </div>

  <ScoreModal v-if="isShowScoreBtn" />
</template>

<script setup>
import { getCurrentInstance, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useUserStore, useCommonStore } from "@/store/index";
import { Modal } from "ant-design-vue";
import { ScoreModal } from "@/components";
import { config } from "@/config";

const { proxy } = getCurrentInstance(),
  { VUE_APP_GAME } = process.env,
  userStore = useUserStore(),
  commonStore = useCommonStore(),
  router = useRoute();

watch(
  () => router.name,
  () => {
    document.querySelector("#main").scrollTo({ top: 0 });
  }
);

const name = computed(() => {
    return userStore.name;
  }),
  phone = computed(() => {
    return userStore.phone;
  }),
  state = computed(() => {
    return userStore.state;
  }),
  isHideTicketBtn = computed(() => {
    return router.name === "ticket" || state.value === 5;
  }),
  isHideSignForm = computed(() => {
    return router.name === "enrollForm" || state.value === 5;
  }),
  isShowScoreBtn = computed(() => {
    // 问欧工为啥return false
    return false;
    return (
      commonStore.comMess &&
      commonStore.comMess.racePeriod == 3 &&
      commonStore.comMess.raceType != 1
    );
  });

const downloadTicket = () => {
    proxy.$api.getPdfState().then((res) => {
      if (res) {
        const { link, state } = res.data;
        let content,
          type = "info";
        if (link) {
          if (state == 1) {
            const a = document.createElement("a");
            a.href = link;
            a.target = "_blank";
            a.click();
          }
        } else {
          if (state == 0)
            content = "当前暂无准考证，请耐心等待准考证下载通知短信。";
          else if (state == 1) {
            type = "error";
            content = "暂无准考证，请联系 赛事咨询 人员。";
          } else if (state == 2) {
            type = "warning";
            content = "下载时间已截止，暂不开放下载。";
          }
        }
        content && proxy.$message[type](content, 5);
      }
    });
  },
  logout = () => {
    Modal.confirm({
      title: "确认退出？",
      okText: "确定",
      cancelText: "取消",
      class: "Modal",
      onOk: () => {
        userStore.logout();

        proxy.$router.push({ path: "/" });
        proxy.$message.success("退出成功", 2);
      },
    });
  };
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  height: 96px;
  background: #266fe8;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 100px 16px 80px;
  box-sizing: border-box;

  .left-box {
    height: fit-content;
    cursor: context-menu;

    .title {
      height: 36px;
      font-family: SF Pro, SF Pro;
      font-weight: 590;
      font-size: 20px;
      color: rgba(255, 255, 255, 0.9);
      line-height: 36px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }

  .right-box {
    display: flex;
    align-items: center;

    .avatar-box {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 58px;
        height: 58px;
      }
    }

    .mess-box {
      margin-left: 17px;
      margin-right: 24px;
      cursor: context-menu;

      .name {
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.9);
        line-height: 28px;
      }

      .account {
        height: 24px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.9);
        line-height: 24px;
      }
    }

    .ant-btn {
      width: 180px;
      height: 57px;
      border-radius: 29px;
      font-size: 20px;
      font-weight: 600;
      border-width: 2px;
      line-height: 28px;
      padding: 0;
      border: unset;

      &.ticket-btn,
      &.sign-btn {
        margin-right: 16px;
        color: rgba(255, 255, 255, 0.9);
        border: 2px solid #ffffff;
      }

      &.logout-btn {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.main {
  width: 100%;
  height: calc(100vh - 96px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>
