<!--
 * @Date: 2023-07-12 15:04:13
 * @LastEditTime: 2023-07-12 15:34:49
-->
<template>
  <!-- 提示语 -->
  <div class="tips" v-if="notice">
    <svg class="svg" fill="#faad14" aria-hidden="true" viewBox="64 64 896 896">
      <path
        d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 010-96 48.01 48.01 0 010 96z"
      ></path>
    </svg>
    <div class="text_box" v-html="notice">
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance, onMounted } from 'vue';

const { proxy } = getCurrentInstance();
onMounted(() => {
  getNoticeInfo();
});

const notice = ref(''),
  getNoticeInfo = () => {
    proxy.$api.getNoticeInfo().then((res) => {
      if (res) notice.value = res.data.replaceAll(/\n/g, '<br />');
    });
  };
</script>

<style lang="less" scoped>
.tips {
  width: fit-content;
  max-width: 800px;
  height: fit-content;
  line-height: 100%;
  position: relative;
  padding: 10px 16px;
  font-size: 13px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  margin-top: 16px;
  display: flex;
  align-items: flex-start;

  .svg {
    width: 1.5em;
    height: 1.5em;
  }

  .text_box {
    margin-left: 5px;
    line-height: 20px;

    span {
      display: inline-block;

      &:nth-of-type(2) {
        margin-top: 10px;
      }
    }
  }
}
</style>
